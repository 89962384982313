import React, { useRef, useState, useEffect } from 'react'
import { MessageRepository } from '@amityco/js-sdk'
import { MessageItem } from '../MessageItem'
import { dateTzFormatter } from '../utils/utils'
import './index.css'
import { ProgressBar } from '../utils/ProgressBar'
import { Icon } from '@thryvlabs/maverick'
import { useDispatch, useSelector } from 'react-redux'
import { setMessageListElementRef } from '../../redux/actions/messagesLayout'

export function MessageList({
  channelId,
  setEditMessage,
  messageListElementRef,
  isActiveChannelDeleted,
  channelMessageCount,
}) {
  const [messages, setMessages] = useState([])
  const [showScrollToLatest, setShowScrollToLatest] = useState(false)
  const [loadProgressVisible, setLoadProgressVisible] = useState(false)
  const { recurly_plan } = useSelector((state) => state.ActiveUser)
  const collection = useRef()
  const dispatcher = useDispatch()

  useEffect(() => {
    setMessages([])
    setLoadProgressVisible(true)
    collection.current = MessageRepository.queryMessages({
      channelId,
    })

    collection.current.on('dataUpdated', (messages) => {
      // If the messages array has a duplicated message, remove it before setting state
      if (
        messages?.length > 0 &&
        messages[messages.length - 1]?.channelSegment ===
          messages[messages.length - 2]?.channelSegment
      ) {
        setMessages(
          filterMessagesByCreatedDate(
            recurly_plan,
            messages.slice(0, messages.length - 1),
          ),
        )
      } else {
        setMessages(filterMessagesByCreatedDate(recurly_plan, messages))
      }
    })

    if (
      collection.current.models?.length > 0 &&
      collection.current.models[collection.current.models.length - 1]
        ?.channelSegment ===
        collection.current.models[collection.current.models.length - 2]
          ?.channelSegment
    ) {
      setMessages(
        filterMessagesByCreatedDate(
          recurly_plan,
          collection.current.models.slice(0, collection.current.models.length - 1),
        ),
      )
    } else {
      setMessages(
        filterMessagesByCreatedDate(recurly_plan, collection.current.models),
      )
    }

    collection.current.once('dataUpdated', () => {
      scrollToLatest()
      checkScrollToLatestVisibility(messageListElementRef)
      setLoadProgressVisible(true)
      setTimeout(() => {
        setLoadProgressVisible(false)
      }, 600)
    })

    if (collection.current.models) {
      setLoadProgressVisible(false)
    }

    dispatcher(setMessageListElementRef(messageListElementRef))
    return () => collection?.current?.dispose()
  }, [channelId, channelMessageCount])

  const onScroll = (e) => {
    checkScrollToLatestVisibility(messageListElementRef)
    if (!collection.current.prevPage) return

    const el = e.target
    // const top = el.scrollHeight - el.clientHeight;
    // previously: const scroll = -el.scrollTop; reversed because of the flex direction (which has been replaced now)
    const scroll = el.scrollTop

    if (scroll < 1) {
      collection.current.prevPage()
      el.scrollTop = 300
    }
  }

  const getYesterdaysDate = (currentDate) => {
    const dateInstance = dateTzFormatter(currentDate)
    dateInstance.setDate(dateInstance.getDate() - 1)
    return dateInstance
  }

  const editMessageInfo = (childdata) => {
    setEditMessage(childdata)
  }

  const setDayMarkerForMessageList = (message, i, a) => {
    const currentCreatedDate = dateTzFormatter(message.createdAt).toDateString()
    let daySeparatorText = false
    if (i > 0) {
      const previousCreatedDate = dateTzFormatter(a[i - 1]?.createdAt).toDateString()
      if (previousCreatedDate !== currentCreatedDate) {
        message.initialMessageOfTheDay = currentCreatedDate
        const todayDate = dateTzFormatter()
        switch (currentCreatedDate) {
          case todayDate.toDateString():
            daySeparatorText = 'Today'
            break
          case getYesterdaysDate(todayDate).toDateString():
            daySeparatorText = 'Yesterday'
            break
          default:
            daySeparatorText = currentCreatedDate
        }
      }
    }
    message.initialMessageOfTheDay = daySeparatorText
  }

  const scrollToLatest = () => {
    messageListElementRef.current.scrollTop =
      messageListElementRef.current.scrollHeight
    messageListElementRef.current.scrollIntoView(false)
  }

  const checkScrollToLatestVisibility = (ref) => {
    const currentRef = ref.current
    setShowScrollToLatest(
      currentRef.offsetHeight + currentRef.scrollTop < currentRef.scrollHeight,
    )
  }

  return (
    <div className="MessageList" ref={messageListElementRef} onScroll={onScroll}>
      <div ref={messageListElementRef}>
        {messages.map((message, i, a) => {
          setDayMarkerForMessageList(message, i, a)
          return message.isDeleted ? (
            ''
          ) : (
            <MessageItem
              editMessageInfo={editMessageInfo}
              key={message.messageId}
              {...message}
              isActiveChannelDeleted={isActiveChannelDeleted}
            />
          )
        })}
      </div>
      <ProgressBar visible={loadProgressVisible} />
      {showScrollToLatest && (
        <span className="scroll-to-latest-button" onClick={scrollToLatest}>
          <Icon
            type="solid"
            variant="chevronDown"
            color="#FFF"
            className="cursor-pointer"
          />
        </span>
      )}
    </div>
  )
}

const getDateInPast = (days = 0) => {
  return new Date(new Date().setDate(new Date().getDate() - days))
}

const filterMessagesByCreatedDate = (recurly_plan, messages) => {
  // If recurly plan doesn't match, show entire message history
  // TODO: Get list of all possible recurly plan names
  const messageCutoffDate =
    recurly_plan === 'basic'
      ? getDateInPast(30)
      : recurly_plan === 'plus'
      ? getDateInPast(365)
      : null
  return messageCutoffDate
    ? messages.filter((message) => {
        return message.createdAt > messageCutoffDate
      })
    : messages
}
