import { useEffect, useState } from 'react'
import {
  ChannelRepository,
  ChannelType,
  ChannelFilter,
  ChannelSortingMethod,
} from '@amityco/js-sdk'

export default function useUserChats() {
  const [chats, setChats] = useState([])
  const [chatsLoading, setChatsLoading] = useState(true)
  const [chatsError, setChatsError] = useState(null)

  useEffect(() => {
    const liveCollection = ChannelRepository.queryChannels({
      types: [ChannelType.Conversation],
      filter: ChannelFilter.Member,
      sortBy: ChannelSortingMethod.LastCreated,
    })
    liveCollection?.on('dataUpdated', (models) => {
      setChats(models)
      setChatsLoading(false)
    })
    liveCollection?.on('loadingStatusChanged', () => {
      if (
        liveCollection &&
        liveCollection.loadingStatus === 'loaded' &&
        liveCollection.hasMore
      ) {
        liveCollection.nextPage()
      }
    })
    liveCollection?.on('dataError', (err) => {
      setChatsError({ code: err?.code, message: err?.message })
    })

    return () => liveCollection?.dispose()
  }, [])

  return {
    chats,
    chatsLoading,
    chatsError,
  }
}
