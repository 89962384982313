import { useEffect, useState } from 'react'
import {
  ChannelRepository,
  ChannelType,
  ChannelFilter,
  ChannelSortingMethod,
} from '@amityco/js-sdk'

export default function useUserChannels() {
  const [channels, setChannels] = useState([])
  const [channelsLoading, setChannelsLoading] = useState(true)
  const [channelsError, setChannelsError] = useState(null)

  useEffect(() => {
    const liveCollection = ChannelRepository.queryChannels({
      types: [ChannelType.Standard, ChannelType.Community, ChannelType.Live],
      filter: ChannelFilter.Member,
      excludeTags: ['deleted'],
      sortBy: ChannelSortingMethod.LastCreated,
    })
    liveCollection?.on('dataUpdated', (models) => {
      setChannels(models)
      setChannelsLoading(false)
    })
    liveCollection?.on('loadingStatusChanged', (models) => {
      if (
        liveCollection &&
        liveCollection.loadingStatus === 'loaded' &&
        liveCollection.hasMore
      ) {
        liveCollection.nextPage()
      }
    })
    liveCollection?.on('dataError', (err) => {
      setChannelsError({ code: err?.code, message: err?.message })
    })

    return () => liveCollection?.dispose()
  }, [])

  return {
    channels,
    channelsLoading,
    channelsError,
  }
}
