import { useEffect, useRef, useState } from 'react'
import { httpGet } from '../../helpers/http_helpers'
import { getAllStaffmembers } from '../../helpers/activation_helpers'
import { useDispatch, useSelector } from 'react-redux'
import { ChannelRepository, MemberFilter, LoadingStatus } from '@amityco/js-sdk'
import { API_URL } from '../../app.config'
import axios from 'axios'
import { inCommandCenter } from '../../components/utils/utils'
import { setCCStaffSyncSuccessful } from '../../redux/actions/ActiveUser'

// For accounts created while webhooks were broken (Old accounts)
// In CC only, adds any staff members that are not already in the TC DB or Amity
export default function useSyncCCTeamMembers(channelId) {
  const [teamchatStaff, setTeamchatStaff] = useState([])
  const [teamchatLoading, setTeamchatLoading] = useState(true)
  const [vcitaStaff, setVcitaStaff] = useState([])
  const [vcitaLoading, setVcitaLoading] = useState(true)
  const [amityStaff, setAmityStaff] = useState([])
  const [amityLoading, setAmityLoading] = useState(true)
  const [error, setError] = useState(null)
  const collection = useRef()
  const { access_token, directory_code, thryv_id, cc_staff_sync_successful } =
    useSelector((state) => state.ActiveUser)
  const dispatch = useDispatch()
  const inCC = inCommandCenter()

  // Get Vcita Staff
  useEffect(() => {
    if (inCC && !cc_staff_sync_successful) {
      const fetchVcitaStaff = async () => {
        const staff = await getAllStaffmembers({
          thryv_id,
          access_token,
          directory_code,
        })
        setVcitaStaff(staff)
        setVcitaLoading(false)
      }
      fetchVcitaStaff()
    }
  }, [])

  // Get Teamchat Staff
  useEffect(() => {
    if (inCC && !cc_staff_sync_successful) {
      httpGet(`getallusers/${thryv_id}`)
        .then((res) => {
          const users = res.data.getUsers;
          setTeamchatStaff(users);
          setTeamchatLoading(false);
        })
        .catch((err) => {
          setError({
            message: 'Failed to get users from teamchat db.',
            error: err.message,
          })
          setTeamchatLoading(false)
        })
    }
  }, [])

  // Get Amity Staff
  useEffect(() => {
    if (inCC && !cc_staff_sync_successful) {
      collection.current = ChannelRepository.queryMembers({
        channelId,
        memberships: [MemberFilter.Member],
      })
      collection.current.on('dataUpdated', (newModels) => {
        const updatedUsers = newModels.filter(
          (member) =>
            member?.userId !== '_admin_admin_teamchat' &&
            member?.userId !== '_admin_teamchat prod-1649169462274d3cba9c-admin',
        )
        setAmityStaff(updatedUsers)

        if (collection.current?.loadingStatus === LoadingStatus.Loaded) {
          collection.current.removeAllListeners();
          setAmityLoading(false);
        }
      })
      return () => collection?.current?.dispose()
    }
  }, [])

  // Compare staff members from each source
  useEffect(() => {
    if (error) {
      return
    }
    if (!teamchatLoading && !vcitaLoading && !amityLoading && !cc_staff_sync_successful) {
      const teamchatIds = teamchatStaff.map((staff) => staff?.thryv_staff_id);
      const vcitaIds = vcitaStaff.map((staff) => staff?.id);
      const amityIds = amityStaff.map((staff) => staff?.userId);

      const idsToAddToTeamchatDatabase = vcitaIds.filter(
        (id) => !teamchatIds.includes(id),
      )
      const idsToAddToAmity = vcitaIds.filter((id) => !amityIds.includes(id))

      // If there are no staff to add, set sync to true so we don't keep fetching data
      if (
        idsToAddToTeamchatDatabase?.length === 0 &&
        idsToAddToAmity?.length === 0
      ) {
        dispatch(setCCStaffSyncSuccessful(true))
        console.log('No CC staff remaining to add to teamchat db or to amity.')
        return
      }

      // Add users to teamchat DB
      if (idsToAddToTeamchatDatabase.length > 0) {
        idsToAddToTeamchatDatabase.forEach((id) => {
          const staffMember = vcitaStaff.find((staff) => staff?.id === id)
          const formattedStaffmemberData = {
            thryv_id: staffMember.business_uid,
            thryv_staff_id: staffMember.id,
            role: staffMember.role,
            notifications: 0,
            guest: 0,
            email: staffMember.email,
            phone: staffMember.mobile_number,
            timezone: null,
            status: 1,
            avatar_url: staffMember.photo,
            display_name: staffMember.display_name
          };
          axios
            .post(`${API_URL}api/teamchat/newuser`, formattedStaffmemberData)
            .then(() => {
              console.log('New user created');
            })
            .catch((err) => console.log(err))
        })
      }

      // Add users to amity
      if (idsToAddToAmity.length > 0) {
        idsToAddToAmity.forEach((id) => {
          const staffMember = vcitaStaff.find((staff) => staff?.id === id)
          const formattedAmityUserData = {
            thryv_id: staffMember.business_uid,
            thryv_staff_id: staffMember.id,
            display_name: staffMember.display_name,
          };
          axios.post(`${API_URL}api/teamchat/amity/newuser`, formattedAmityUserData)
          .then(() => {
          }).catch((err) => console.log(err));
        });
      }
    }
  }, [
    teamchatStaff,
    vcitaStaff,
    amityStaff,
    teamchatLoading,
    vcitaLoading,
    amityLoading,
    error,
  ])
}
