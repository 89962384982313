import React, { useRef, useState, useEffect } from 'react'
import { ChannelRepository, ChannelFilter, ChannelType } from '@amityco/js-sdk'
import { ConversationItem } from '../ConversationItem'
import { httpGet } from '../../helpers/http_helpers'
import { getStorageItem, setStorageItem } from '../../helpers/environment_helpers'
import './index.css'
import { Button } from '@thryvlabs/maverick'
import { useSocketDispatchContext } from '../../contexts/SocketContext'
import axios from 'axios'
import { API_URL } from '../../app.config'

export function ConversationList({ activeChannelId, onClick = () => {} }) {
  const [channels, setChannels] = useState([])
  const [showViewMore, setShowViewMore] = useState(false)

  const [top, setTop] = useState(true)
  const collection = useRef()

  const currentUser = getStorageItem('ActiveUserId')

  const socketDispatch = useSocketDispatchContext()

  const getChannelMembersStatus = (models) => {
    let thryv_id = getStorageItem('Thryv_Id')
    let thryv_staff_id = getStorageItem('Thryv_Staff_Id')

    httpGet(`getallusers/${thryv_id}`).then((res) => {
      const users = res.data.getUsers
      models?.forEach((x) => {
        let member = x.metadata.convoMembersDetailedList?.find(
          (m) => m?.thryv_staff_id !== thryv_staff_id,
        )
        let dbUser = users.find((y) => y.thryv_staff_id === member?.thryv_staff_id)
        if (member && dbUser) {
          member.status = dbUser.status
        }
      })
      if (channels) {
        setChannels(models)
      }

      // If chat displayName or metadata includes stale staffmember name, update displayName and metadata
      let channelsToUpdate = {}
      models?.forEach((channel) => {
        let channelUsers = channel.metadata.convoMembersDetailedList
        channelUsers?.forEach((user) => {
          let dbUser = users.find((u) => u.thryv_staff_id === user.thryv_staff_id)
          if (dbUser?.display_name !== user.display_name) {
            let updatedConvoMembers = channelUsers.map((u) => {
              if (u.thryv_staff_id === user.thryv_staff_id) {
                return dbUser
              }
              return u
            })
            let updatedDisplayName
            if (channelUsers.length > 2) {
              const chatMembers = updatedConvoMembers.map(
                (member) => member.display_name,
              )
              const lastMember = chatMembers.pop()
              updatedDisplayName = `${chatMembers.join(', ')} and ${lastMember}`
            }
            let update = {
              channelId: channel.channelId,
              user: dbUser,
              metadata: {
                coverImageUrl: channel.metadata?.coverImageUrl,
                convoMembersDetailedList: updatedConvoMembers,
              },
              displayName: updatedDisplayName || channel?.displayName,
            }
            channelsToUpdate[channel?.channelId] = update
          }
        })
      })

      let channelUpdates = Object.keys(channelsToUpdate)
      if (channelUpdates.length) {
        channelUpdates?.forEach((channelId) => {
          ChannelRepository.updateChannel({
            channelId: channelId,
            displayName: channelsToUpdate[channelId].displayName,
            metadata: channelsToUpdate[channelId].metadata,
          })
        })
      }
    })
  }

  useEffect(() => {
    collection.current = ChannelRepository.queryChannels({
      types: [ChannelType.Conversation],
      filter: ChannelFilter.Member,
      excludeTags: ['deleted'],
    })

    collection.current?.on('loadingStatusChanged', () => {
      setShowViewMore(collection.current.hasMore)
    })

    collection.current?.on('dataUpdated', (models) => {
      models?.forEach((obj) => {
        obj.metadata.convoMembersDetailedList =
          obj?.metadata?.convoMembersDetailedList?.filter((item) => item !== null)
      })

      setChannels(models)
      getChannelMembersStatus(models)
    })

    setChannels(collection?.current?.models)

    return () => collection?.current?.dispose()
  }, [])

  const onScroll = (e) => {
    setTop(e.target.scrollTop === 0)
    if (!collection.current.hasMore) return

    const visibleHeight = e.target.scrollHeight - e.target.clientHeight

    if (e.target.scrollTop >= visibleHeight - 1) {
      collection.current?.nextPage()
    }
  }

  const handleViewMoreChannels = () => {
    if (collection.current.nextPage) {
      collection.current.nextPage()
    }
  }

  useEffect(() => {
    const chatUsers = []
    channels?.forEach((channel) => {
      channel.metadata.convoMembersDetailedList?.forEach((member) => {
        if (member.thryv_staff_id !== currentUser)
          chatUsers.push(member.thryv_staff_id)
      })
    })

    if (chatUsers.length) {
      axios
        .get(`${API_URL}api/teamchat/getstatususers?thryv_ids=${chatUsers}`)
        .then((res) => {
          const data = {}
          res?.data?.forEach((status) => {
            data[status.thryv_staff_id] = {
              id: status.status_icon,
              userId: status.thryv_staff_id,
              value: status.status_value,
            }
          })
          socketDispatch({ type: 'allUserStatuses', payload: { data } })
        })
        .catch((err) => {
          console.log('Error from getting all users statuses from DB', err)
        })
    }

    if (currentUser) {
      axios
        .get(`${API_URL}api/teamchat/getstatus/${currentUser}`)
        .then((res) => {
          const status_value_from_db = res?.data?.convertedBufferObject?.status_value
          if (
            status_value_from_db !== 'undefined' &&
            status_value_from_db !== '' &&
            status_value_from_db !== null
          ) {
            setStorageItem(
              'setStatusIconForSelf',
              res?.data?.convertedBufferObject?.status_icon,
            )
            setStorageItem(
              'setStatusValueForSelf',
              res?.data?.convertedBufferObject?.status_value,
            )
          }
        })
        .catch((err) => {
          console.log('Error form getting current user status DB', err)
        })
    }
  }, [channels])

  return (
    <div className={`ConversationList ${top ? 'top' : ''}`} onScroll={onScroll}>
      {channels?.map((channel) => (
        <ConversationItem
          key={channel.channelId}
          {...channel}
          active={channel.channelId === activeChannelId}
          onClick={onClick}
        />
      ))}
      {showViewMore ? (
        <Button
          className="view-more-button"
          variant="text"
          level={1}
          textVariant="light"
          onClick={() => handleViewMoreChannels()}
        >
          View more...
        </Button>
      ) : null}
    </div>
  )
}
