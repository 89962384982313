import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { ChannelHeader } from '../ChannelHeader'
import { MessageList } from '../MessageList'
import { MessageComposerContainer } from '../MessageComposer'
import { ReferencePanelSideSheet } from '../ReferencePanel/ReferencePanelSideSheet'
import './index.css'
import { useSocketStateContext } from '../../contexts/SocketContext'
import { FlyoutOpenButton } from '../ChatFlyout/ToggleFlyoutButtons'
import { ChannelRepository } from '@amityco/js-sdk'
import { NewConversation } from '../NewConversation'
import { CHAT_ROOM_TYPE } from '../../pages/Main/constants'

const hiddenProp = ['hidden', 'msHidden', 'webkitHidden'].find(
  (prop) => prop in document,
)

const visibilityEvent = {
  hidden: 'visibilitychange',
  msHidden: 'msvisibilitychange',
  webkitHidden: 'webkitvisibilitychange',
}[hiddenProp]

export function ChatRoom({
  channelId,
  hashTag,
  setHashTag,
  setChannelId,
  chatRoomType,
}) {
  const [key, setKey] = useState(Date.now())
  const [online, setOnline] = useState(navigator.onLine)
  const [editMessage, setEditMessage] = useState('')
  const { displayChannelBrowser } = useSelector((state) => state.channelBrowser)
  const { isFlyoutOpen } = useSelector((state) => state.Flyout)
  const messageListElementRef = useRef(null)
  const [channel, setChannel] = useState({})

  useEffect(() => {
    if (channelId) {
      const liveObject = ChannelRepository.getChannel(channelId)
      liveObject.on('dataUpdated', setChannel)
      liveObject.model && setChannel(liveObject.model)
      return () => liveObject?.dispose()
    }
  }, [channelId])

  const chatRoomHeaderComponents = {
    [CHAT_ROOM_TYPE.NORMAL]: channelId && <ChannelHeader channelId={channelId} />,
    [CHAT_ROOM_TYPE.NEW_CONVERSATION]: (
      <NewConversation channelId={channelId} changeChannelId={setChannelId} />
    ),
  }

  const socketState = useSocketStateContext()

  const refreshKey = () => {
    navigator.onLine && // internet is back
      !document[hiddenProp] && // page is not hidden
      setKey(Date.now())
  }

  const updateOnlineStatus = () => {
    if (navigator.onLine !== online) {
      setOnline(navigator.onLine)
    }
  }

  useEffect(() => {
    window.addEventListener('online', updateOnlineStatus)
    document.addEventListener(visibilityEvent, updateOnlineStatus)

    return () => {
      window.addEventListener('online', updateOnlineStatus)
      document.removeEventListener(visibilityEvent, updateOnlineStatus)
    }
  })

  useEffect(() => {
    if (online) {
      refreshKey()
    }
  }, [online])

  const getTypers = () => {
    const ary = []

    const allTypers = Object.values(socketState.typers)

    if (allTypers.length > 1) {
      ary.push(
        <div className="typing_container">
          <div className="circle">
            <span className="typing-dot"></span>
            <span className="typing-dot"></span>
            <span className="typing-dot"></span>
          </div>
          <div className="typing" style={{ fontSize: '12px', marginLeft: '10px' }}>
            {' '}
            {allTypers[0]?.userName} and {allTypers[1]?.userName} are typing...
          </div>
        </div>,
      )
    } else if (allTypers.length > 2) {
      ary.push(
        <div className="typing_container">
          <div className="circle">
            <span className="typing-dot"></span>
            <span className="typing-dot"></span>
            <span className="typing-dot"></span>
          </div>
          <div className="typing" style={{ fontSize: '12px', marginLeft: '10px' }}>
            {' '}
            Several people are typing...
          </div>
        </div>,
      )
    } else {
      Object.values(socketState.typers).forEach((data, i) => {
        if (data.channelId === channelId)
          ary.push(
            <div className="typing_container" key={i}>
              <div className="circle">
                <span className="typing-dot"></span>
                <span className="typing-dot"></span>
                <span className="typing-dot"></span>
              </div>
              <div
                className="typing"
                style={{ fontSize: '12px', marginLeft: '10px' }}
              >
                {' '}
                {data.userName} is typing...
              </div>
            </div>,
          )
      })
    }

    return ary
  }

  const isActiveChannelDeleted = channel?.tags?.includes('deleted')

  return (
    <div key={key} className={`ChatRoom ${displayChannelBrowser ? 'overlay' : ''}`}>
      {chatRoomHeaderComponents[chatRoomType]}
      {channelId ? (
        <MessageList
          messageListElementRef={messageListElementRef}
          setEditMessage={setEditMessage}
          channelId={channelId}
          isActiveChannelDeleted={isActiveChannelDeleted}
          channelMessageCount={channel?.messageCount}
        />
      ) : (
        <div className="MessageList" />
      )}
      {/* <div className='seperator'>&nbsp;</div> */}
      {Object.keys(socketState.typers).length > 0 && (
        <div className="typing_container_wrapper">{getTypers()}</div>
      )}

      {(!isActiveChannelDeleted ||
        chatRoomType === CHAT_ROOM_TYPE.NEW_CONVERSATION) && (
        <MessageComposerContainer
          parentProps={{
            channelId,
            editMessage,
            messageListElementRef,
            chatRoomType,
            setChannelId,
          }}
          modalProps={{ title: 'Tag Client Activity' }}
        />
      )}
      <ReferencePanelSideSheet />
      {!isFlyoutOpen && <FlyoutOpenButton />}
    </div>
  )
}
