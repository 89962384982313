import React, { useEffect, useState, useRef } from 'react'
import './index.css'
import { Avatar, Input, Icon } from '@thryvlabs/maverick'
import Loader from 'react-loader-spinner'
import { AddButton, REFERENCE_ELEMENT_TYPE, REFERENCE_ELEMENTS } from '../index'
import axios from 'axios'
import { API_URL } from '../../../app.config'
import { BeatPulseIcon } from '../../Icons'
import { getAvatarColorByName } from '../../utils/utils'
import { useSelector } from 'react-redux'

export const ClientList = ({
  closeModal,
  title,
  setActiveComponent,
  setSelectedClient,
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [isTyping, setIsTyping] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [hasSearched, setHasSearched] = useState(false)
  const typingTimeoutRef = useRef(null)
  const [resultClientList, setResultClientList] = useState([])
  const [showRecents, setShowRecents] = useState(true)
  const [recents, setRecents] = useState([])
  const { thryv_id, access_token, directory_code } = useSelector(
    (state) => state.ActiveUser,
  )
  const { clientList } = useSelector((state) => state.ClientList)

  useEffect(() => {
    const recentClients = JSON.parse(localStorage.getItem('recentClients'))
    setRecents(recentClients || [])
  }, [])

  // Helper function to filter clients based on search term
  const filterClients = (clients, term) => {
    return clients.filter(
      (contact) =>
        (contact.first_name &&
          contact.last_name &&
          `${contact?.first_name} ${contact?.last_name}`
            .toLowerCase()
            .includes(term.toLowerCase())) ||
        (contact.first_name &&
          contact.first_name.toLowerCase().includes(term.toLowerCase())) ||
        (contact.mobile_phone && contact.mobile_phone.includes(term)) ||
        (contact.normalized_phone && contact.normalized_phone.includes(term)) ||
        (contact.email && contact.email.toLowerCase().includes(term.toLowerCase())),
    )
  }

  useEffect(() => {
    // Reset search if no search term or no client list
    if (searchTerm === null || searchTerm === '') {
      setResultClientList(clientList)
      setShowRecents(true)
      setHasSearched(false)
      return
    }

    // Only search if user has stopped typing and search term is longer than 1 character
    if (!isTyping && searchTerm.length > 1) {
      const performSearch = async () => {
        setIsLoading(true)
        setResultClientList([])

        try {
          const requestPayload = {
            thryv_id,
            access_token,
            keyword: searchTerm,
            dirCode: directory_code,
            per_page: 100,
          }

          const response = await axios.post(
            `${API_URL}api/teamchat/clients/search_keyword`,
            requestPayload,
          )
          const apiResults = response?.data?.data?.clients || []

          // Filter API results and local client list
          const filteredApiResults = filterClients(apiResults, searchTerm)
          const filteredLocalResults = filterClients(clientList, searchTerm)

          // Combine and deduplicate results
          const finalRes = [
            ...new Map(
              [...filteredApiResults, ...filteredLocalResults].map((obj) => [
                obj.id,
                obj,
              ]),
            ).values(),
          ]

          setResultClientList(finalRes)
          setShowRecents(false)
          setHasSearched(true)
        } catch (error) {
          // If API fails, still show local matches
          console.error('Search error:', error)
          const localMatches = filterClients(clientList, searchTerm)
          setResultClientList(localMatches)
          setHasSearched(true)
        } finally {
          setIsLoading(false)
        }
      }
      performSearch()
    }
  }, [isTyping, searchTerm, clientList, thryv_id, access_token, directory_code])

  const handleClientSelect = (v) => {
    setSelectedClient(v)
    setActiveComponent(REFERENCE_ELEMENTS.CLIENT_PROFILE)

    // Update recents list
    const recentClient = recents.find((e) => e.id === v.id)
    if (recentClient) {
      recents.splice(recents.indexOf(recentClient), 1)
    }
    recents.unshift(v)
    if (recents.length > 3) {
      recents.pop()
    }
    setRecents(recents)
    localStorage.setItem('recentClients', JSON.stringify(recents))
  }

  const handleSearchInput = (e) => {
    setIsTyping(true)
    setSearchTerm(e.target.value?.trim()?.toLowerCase())

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }

    typingTimeoutRef.current = setTimeout(() => {
      setIsTyping(false)
    }, 200)
  }

  const renderSearchResults = () => {
    if (isLoading) {
      return (
        <Loader
          className="loading-dots"
          type="ThreeDots"
          color="#FF5000"
          height={80}
          width={80}
        />
      )
    }

    if (hasSearched && resultClientList.length === 0) {
      return (
        <div className="no-results p-4 text-center text-muted">
          <p>No clients found matching "{searchTerm}".</p>
        </div>
      )
    }

    return resultClientList.map((v) => (
      <div
        className={`result-item`}
        key={v.id}
        onClick={() => handleClientSelect(v)}
      >
        <div className="avatar">
          <Avatar
            className={'RefClientListAvatar'}
            variant="name"
            size="default"
            style={{
              backgroundColor: getAvatarColorByName(v?.first_name),
            }}
            name={{
              firstName: v?.first_name,
              lastName: v?.last_name,
            }}
          />
        </div>
        <span className={'display-name RefClientListLabelText'}>
          {`${v?.first_name ?? ''} ${v?.last_name ?? ''}`}
        </span>
        <div className="client-email status">{v?.email ?? 'No email'}</div>
        <div className={'chevronRight'}>
          <div className={'d-inline-flex gap-3'}>
            <span className={'client-list-hover-add-button'}>
              <AddButton
                selectedValue={v}
                closeModal={closeModal}
                type={REFERENCE_ELEMENT_TYPE.CLIENT}
              />
            </span>
            <span>
              <Icon type="regular" height={15} width={15} variant="chevronRight" />
            </span>
          </div>
        </div>
      </div>
    ))
  }

  return (
    <>
      <div className={'RefPanelTitleBar d-flex'}>
        <span className="title-text my-auto ml-2">
          <span className={'px-2'}>
            <BeatPulseIcon />
          </span>
          {'Tag Client Activity'}
          <span className={'px-2'}>
            <BeatPulseIcon />
          </span>
        </span>
      </div>
      <div className={'reference-panel-container'}>
        <LabelText text={'Search Client by Name, Email or Phone'} />
        <Input
          type="text"
          className={'w-100 mb-3'}
          onChange={handleSearchInput}
          placeholder="Search"
          name="search"
          variant="search-isolated"
        />

        {showRecents && (
          <>
            <LabelText text={'Recents'} />
            <div className={'d-flex justify-content-start'}>
              {recents?.map((v) => (
                <div
                  className={`recent-item`}
                  key={v.id}
                  onClick={() => handleClientSelect(v)}
                >
                  <div className="avatar">
                    <Avatar
                      variant="name"
                      size="small"
                      style={{
                        backgroundColor: getAvatarColorByName(v?.first_name),
                      }}
                      name={{
                        firstName: v?.first_name,
                        lastName: v?.last_name,
                      }}
                    />
                  </div>
                  <span className={'display-name RefPanelLabelText'}>
                    {`${v?.first_name ?? ''} ${v?.last_name ?? ''}`}
                  </span>
                </div>
              ))}
            </div>
          </>
        )}

        <LabelText text={'Results'} />
        <div className={'list'}>{renderSearchResults()}</div>
      </div>
    </>
  )
}

const LabelText = ({ text }) => {
  return (
    <div className={'d-flex justify-content-start mt-2'}>
      <span className="RefPanelLabelText RefLabelColor">{text}</span>
    </div>
  )
}
